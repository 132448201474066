export const MODAL_ANIMATION = 300;
export const MICRO_MODAL_ANIMATION = 200;
export const NAVIGATION_PANE_EXPAND_COLLAPSE = 300;
export const NAVIGATION_PANE_FADE = 200;
export const DETAIL_PANE_EXPAND_COLLAPSE = 500;
export const DETAIL_PANE_FADE = 200;
export const HELPER_SLIDE_OUT = 500;
export const GRID_ITEM_MENU_FADE = 200;

export default {
  MODAL_ANIMATION,
  NAVIGATION_PANE_EXPAND_COLLAPSE,
  NAVIGATION_PANE_FADE,
  DETAIL_PANE_EXPAND_COLLAPSE,
  DETAIL_PANE_FADE,
  GRID_ITEM_MENU_FADE,
  HELPER_SLIDE_OUT,
  MICRO_MODAL_ANIMATION,
};
