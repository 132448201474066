import { useEffect } from 'react';

export const useClickAway = (
  ref,
  onClickAway = () => false,
  isCapture = false,
  dependencies = [],
) => {
  // from https://usehooks.com/useOnClickOutside/

  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (isCapture) {
          event.stopImmediatePropagation();
        }

        if (!ref?.current || ref?.current?.contains(event.target)) {
          return;
        }

        onClickAway(event);
      };

      const attachHandler = dependencies.length
        ? dependencies.find((element) => element)
        : true;

      if (attachHandler) {
        document.addEventListener('mousedown', listener, isCapture);
        document.addEventListener('touchstart', listener, isCapture);
      }

      return () => {
        if (attachHandler) {
          document.removeEventListener('mousedown', listener, isCapture);
          document.removeEventListener('touchstart', listener, isCapture);
        }
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [onClickAway, ref, ...dependencies],
  );
};

export default useClickAway;
