// adopted from https://github.com/NansD/use-state-if-mounted
import { useEffect, useRef } from 'react';

export const useIsComponentMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export default useIsComponentMounted;
