import styled from 'styled-components';

export default styled.div`
  position: absolute;
  bottom: -4px;
  width: 12px;
  height: 4px;
  margin-left: -6px;
  border-top: 4px solid ${({ theme }) => theme.BaseColors.White};
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  box-sizing: border-box;

  &.top {
    left: 50%;
  }

  &.right {
    left: -2px;
    transform: rotate(90deg);
    top: 50%;
  }

  &.bottom {
    transform: rotate(180deg);
    top: -4px;
    left: 50%;
  }

  &.left {
    left: 100%;
    transform: rotate(-90deg);
    top: 50%;
    margin-left: -4px;
  }
`;
