export const generateTooltipOffsets = (
  tooltipWidth,
  tooltipHeight,
  anchorHeight,
  anchorWidth,
  caretWidth = 12,
  caretLength = 4,
  caretToEdge = 12,
  caretToAnchor = 8,
  wrapper = {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
) => {
  /* eslint-disable max-len */
  return {
    'top-center': [
      `${wrapper.top + window.pageYOffset - (tooltipHeight + caretToEdge)}px`,
      null,
      null,
      `${
        wrapper.left + window.pageXOffset + anchorWidth / 2 - tooltipWidth / 2
      }px`,
    ],
    'top-left': [
      `${wrapper.top + window.pageYOffset - (tooltipHeight + caretToEdge)}px`,
      null,
      null,
      `${
        wrapper.left +
        window.pageXOffset -
        (anchorWidth / 2 + tooltipWidth / 2 + caretLength)
      }px`,
    ],
    'top-right': [
      `${wrapper.top + window.pageYOffset - (tooltipHeight + caretToEdge)}px`,
      null,
      null,
      `${wrapper.left + window.pageXOffset + caretWidth / 2}px`,
    ],
    'bottom-center': [
      `${wrapper.top + window.pageYOffset + anchorHeight + caretToEdge}px`,
      null,
      null,
      `${
        wrapper.left + window.pageXOffset + anchorWidth / 2 - tooltipWidth / 2
      }px`,
    ],
    'bottom-left': [
      `${wrapper.top + window.pageYOffset + anchorHeight + caretToEdge}px`,
      null,
      null,
      `${
        wrapper.left + window.pageXOffset - anchorWidth / 2 - tooltipWidth / 2
      }px`,
    ],
    'bottom-right': [
      `${wrapper.top + window.pageYOffset + anchorHeight + caretToEdge}px`,
      null,
      null,
      `${wrapper.left + window.pageXOffset + caretToEdge}px`,
    ],
    'left-up': [
      `${
        wrapper.top +
        window.pageYOffset +
        anchorHeight / 2 -
        caretToEdge -
        caretWidth / 2
      }px`,
      null,
      null,
      `${
        wrapper.left +
        window.pageXOffset -
        tooltipWidth -
        caretLength -
        caretWidth
      }px`,
    ],
    'left-center': [
      `${
        wrapper.top + window.pageYOffset + anchorHeight / 2 - tooltipHeight / 2
      }px`,
      null,
      null,
      `${
        wrapper.left +
        window.pageXOffset -
        tooltipWidth -
        caretLength -
        caretWidth
      }px`,
    ],
    'left-down': [
      `${
        wrapper.top +
        window.pageYOffset +
        anchorHeight / 2 -
        caretToEdge -
        caretWidth / 2
      }px`,
      null,
      null,
      `${
        wrapper.left +
        window.pageXOffset -
        tooltipWidth -
        caretLength -
        caretWidth
      }px`,
    ],
    'right-up': [
      `${
        wrapper.top +
        window.pageYOffset +
        anchorHeight / 2 -
        caretToEdge -
        caretWidth / 2
      }px`,
      null,
      null,
      `${wrapper.right + window.pageXOffset + caretLength + caretToAnchor}px`,
    ],
    'right-center': [
      `${
        wrapper.top + window.pageYOffset + anchorHeight / 2 - tooltipHeight / 2
      }px`,
      null,
      null,
      `${wrapper.right + window.pageXOffset + caretLength + caretToAnchor}px`,
    ],
    'right-down': [
      `${
        wrapper.top +
        window.pageYOffset +
        anchorHeight / 2 -
        caretToEdge -
        caretWidth / 2
      }px`,
      null,
      null,
      `${wrapper.right + window.pageXOffset + caretLength + caretToAnchor}px`,
    ],
  };
  /* eslint-enable max-len */
};

export default generateTooltipOffsets;
