import { useState, useEffect } from 'react';
import { generateTooltipOffsets } from '../helpers/tooltipHelpers';

export const useTooltip = (wrapperRef, tooltipRef, props) => {
  const [isTooltipShown, setTooltipShown] = useState(false);
  const [anchorHeight, setAnchorHeight] = useState(0);
  const [anchorWidth, setAnchorWidth] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);

  useEffect(() => {
    const wrapper = wrapperRef?.current?.getBoundingClientRect
      ? wrapperRef.current.getBoundingClientRect()
      : {};
    const tooltip = tooltipRef?.current?.getBoundingClientRect
      ? tooltipRef.current.getBoundingClientRect()
      : {};
    setAnchorHeight(Math.floor(wrapper.height || 0));
    setAnchorWidth(Math.floor(wrapper.width || 0));
    setTooltipHeight(Math.floor(tooltip.height || 0));
    setTooltipWidth(Math.floor(tooltip.width || 0));
  }, [wrapperRef, tooltipRef, props]);

  const {
    tooltipPosition = 'top-center',
    caretWidth = 12,
    caretLength = 4,
    caretToEdge = 12,
    caretToAnchor = 8,
  } = props || {};
  return {
    toggleTooltip: setTooltipShown,
    isTooltipShown,
    offsets: generateTooltipOffsets(
      tooltipWidth,
      tooltipHeight,
      anchorHeight,
      anchorWidth,
      caretWidth,
      caretLength,
      caretToEdge,
      caretToAnchor,
      wrapperRef?.current?.getBoundingClientRect
        ? wrapperRef.current.getBoundingClientRect()
        : undefined,
    )[tooltipPosition],
  };
};

export default useTooltip;
