import {
  POSITIVE_NOTIFICATION_TYPE,
  NOTIFICATION_AUTODISMISS_TIMEOUT_MILLISECONDS,
} from './constants';

/**
 * shouldAutoDismiss
 * ========================
 * If we are missing the autoDismiss prop, we want to auto dismiss if the type is positive
 * @param {boolean} [autoDismiss = false] if `true` NotificationBanner disappears after time period
 * @param {string} [type = 'positive'] `type` prop from NotificationBanner
 * @returns {boolean}
 */
export const shouldAutoDismiss = (autoDismiss, type) => {
  if (typeof autoDismiss !== 'boolean')
    return type === POSITIVE_NOTIFICATION_TYPE;
  return autoDismiss;
};

/**
 * setUpAutoDismiss
 * ========================
 * Sets and starts timer on NotificationBanner mount
 * @param {boolean} [autoDismiss = false] if `true` NotificationBanner hides after time period
 * @param {Function} close action which 'closes' the component
 * @param {boolean} isText if `true` NotificationBanner contains text content
 * @param {number} timer time in MS before NotificationBanner hides
 * @param {string} [type = 'positive'] `type` prop from NotificationBanner
 * @returns {Object} A NodeJS.Timeout handle used to cancel the timeout
 */
export const setUpAutoDismiss = (autoDismiss, close, text, timer, type) => {
  const notificationTimer =
    timer || NOTIFICATION_AUTODISMISS_TIMEOUT_MILLISECONDS;
  if (shouldAutoDismiss(autoDismiss, type) && text) {
    const dismissAction = () => close();
    return setTimeout(dismissAction, notificationTimer);
  }
  return null;
};

export default {
  setUpAutoDismiss,
  shouldAutoDismiss,
};
