export const SMALL_BUTTON_HEIGHT = 25;
export const MEDIUM_BUTTON_HEIGHT = 29;
export const LARGE_BUTTON_HEIGHT = 36;
export const MASTHEAD_HEIGHT = 48;

export default {
  SMALL_BUTTON_HEIGHT,
  MEDIUM_BUTTON_HEIGHT,
  LARGE_BUTTON_HEIGHT,
  MASTHEAD_HEIGHT,
  input: {
    small: 28,
    medium: 36,
    large: 44,
  },
};
