// adopted from https://github.com/NansD/use-state-if-mounted
import { useState } from 'react';
import useIsComponentMounted from './useIsComponentMounted';

export const useStateIfMounted = (initialValue) => {
  const isComponentMounted = useIsComponentMounted();
  const [state, setState] = useState(initialValue);

  const newSetState = (value) => {
    if (isComponentMounted.current) {
      setState(value);
    }
  };

  return [state, newSetState];
};

export default useStateIfMounted;
