import styled, { css } from 'styled-components';

export const A11y = {
  visuallyhidden: css`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
  `,
};

export const VisuallyHiddenText = styled.span`
  ${A11y.visuallyhidden};
`;

export default {
  A11y,
  VisuallyHiddenText,
};
