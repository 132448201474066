export const digitalGreen130 = '#358600';
export const digitalGreen100 = '#44AD00';
export const digitalGreen70 = '#7DC54E';
export const digitalGreen50 = '#A3D582';
export const digitalGreen30 = '#C9E5B6';
export const digitalGreen15 = '#E4F2DB';

export const digitalGreen = {
  130: digitalGreen130,
  100: digitalGreen100,
  70: digitalGreen70,
  50: digitalGreen50,
  30: digitalGreen30,
  15: digitalGreen15,
};

export const digitalOrange100 = '#FFC107';
export const digitalOrange70 = '#FFD352';
export const digitalOrange50 = '#FFDF84';
export const digitalOrange30 = '#FFEBB6';
export const digitalOrange15 = '#FFF5DB';

export const digitalOrange = {
  100: digitalOrange100,
  70: digitalOrange70,
  50: digitalOrange50,
  30: digitalOrange30,
  15: digitalOrange15,
};

export const digitalRed150 = '#A10B0B';
export const digitalRed130 = '#BF1616';
export const digitalRed100 = '#D63E3E';
export const digitalRed70 = '#E47979';
export const digitalRed50 = '#EC9F9F';
export const digitalRed30 = '#F4C5C5';
export const digitalRed15 = '#FAE2E2';

export const digitalRed = {
  150: digitalRed150,
  130: digitalRed130,
  100: digitalRed100,
  70: digitalRed70,
  50: digitalRed50,
  30: digitalRed30,
  15: digitalRed15,
};

export const magenta150 = '#7F0D55';
export const magenta130 = '#A41670';
export const magenta115 = '#CD2B92';
export const magenta100 = '#EA40AC';
export const magenta70 = '#F079C4';
export const magenta50 = '#F49FD4';
export const magenta30 = '#F8C5E4';
export const magenta15 = '#FCE2F2';

export const magenta = {
  150: magenta150,
  130: magenta130,
  115: magenta115,
  100: magenta100,
  70: magenta70,
  50: magenta50,
  30: magenta30,
  15: magenta15,
};

export const purple150 = '#3D0064';
export const purple130 = '#4D007E';
export const purple115 = '#6A00AE';
export const purple100 = '#8400D9';
export const purple70 = '#A84EE5';
export const purple50 = '#C082ED';
export const purple30 = '#D8B6F5';
export const purple15 = '#ECDBFA';

export const purple = {
  150: purple150,
  130: purple130,
  115: purple115,
  100: purple100,
  70: purple70,
  50: purple50,
  30: purple30,
  15: purple15,
};

export const periwinkle150 = '#323C8C';
export const periwinkle130 = '#404DB3';
export const periwinkle115 = '#4E5ED9';
export const periwinkle100 = '#5C6FFF';
export const periwinkle70 = '#8B98FF';
export const periwinkle50 = '#B0B9FF';
export const periwinkle30 = '#D6DAFF';
export const periwinkle15 = '#EDEFFF';

export const periwinkle = {
  150: periwinkle150,
  130: periwinkle130,
  115: periwinkle115,
  100: periwinkle100,
  70: periwinkle70,
  50: periwinkle50,
  30: periwinkle30,
  15: periwinkle15,
};

export const brightBlue150 = '#076592';
export const brightBlue130 = '#1188C1';
export const brightBlue115 = '#2CADEA';
export const brightBlue100 = '#4CC6FF';
export const brightBlue70 = '#82D8FF';
export const brightBlue50 = '#A6E4FF';
export const brightBlue30 = '#CAF0FF';
export const brightBlue15 = '#E5F8FF';

export const brightBlue = {
  150: brightBlue150,
  130: brightBlue130,
  115: brightBlue115,
  100: brightBlue100,
  70: brightBlue70,
  50: brightBlue50,
  30: brightBlue30,
  15: brightBlue15,
};

export const blue150 = '#014161';
export const blue130 = '#025077';
export const blue115 = '#0A638F';
export const blue100 = '#1978A5';
export const blue70 = '#3C92BC';
export const blue50 = '#6EB3D4';
export const blue30 = '#B3D9EC';
export const blue15 = '#D5EFF7';

export const blue = {
  150: blue150,
  130: blue130,
  115: blue115,
  100: blue100,
  70: blue70,
  50: blue50,
  30: blue30,
  15: blue15,
};

export const teal150 = '#016363';
export const teal130 = '#007777';
export const teal115 = '#009696';
export const teal100 = '#00B1B1';
export const teal70 = '#43C6C6';
export const teal50 = '#80D9D9';
export const teal30 = '#B5EAEA';
export const teal15 = '#D6F3F3';

export const teal = {
  150: teal150,
  130: teal130,
  115: teal115,
  100: teal100,
  70: teal70,
  50: teal50,
  30: teal30,
  15: teal15,
};

export const green150 = '#007B3D';
export const green130 = '#039D4F';
export const green115 = '#05B75D';
export const green100 = '#16D373';
export const green70 = '#54DB96';
export const green50 = '#85EDB8';
export const green30 = '#B1F6D3';
export const green15 = '#D9FAE9';

export const green = {
  150: green150,
  130: green130,
  115: green115,
  100: green100,
  70: green70,
  50: green50,
  30: green30,
  15: green15,
};

export const mint150 = '#26805B';
export const mint130 = '#30A474';
export const mint115 = '#3BC78E';
export const mint100 = '#46EAA7';
export const mint70 = '#7FF0C2';
export const mint50 = '#A5F4D4';
export const mint30 = '#CBF8E6';
export const mint15 = '#E5FCF3';

export const mint = {
  150: mint150,
  130: mint130,
  115: mint115,
  100: mint100,
  70: mint70,
  50: mint50,
  30: mint30,
  15: mint15,
};

export const yellow115 = '#F9DF19';
export const yellow100 = '#FFEA4C';
export const yellow70 = '#FFEF77';
export const yellow50 = '#FFF184';
export const yellow30 = '#FFF6AC';
export const yellow15 = '#FCF8D9';

export const yellow = {
  115: yellow115,
  100: yellow100,
  70: yellow70,
  50: yellow50,
  30: yellow30,
  15: yellow15,
};

export const orange150 = '#8C5C24';
export const orange130 = '#BF7D32';
export const orange115 = '#E6973C';
export const orange100 = '#FFA843';
export const orange70 = '#FFB969';
export const orange50 = '#FFC889';
export const orange30 = '#FFDDB7';
export const orange15 = '#FFEFDE';

export const orange = {
  150: orange150,
  130: orange130,
  115: orange115,
  100: orange100,
  70: orange70,
  50: orange50,
  30: orange30,
  15: orange15,
};

export const sunset150 = '#8B3A24';
export const sunset130 = '#B2492E';
export const sunset115 = '#D85A38';
export const sunset100 = '#FE6A43';
export const sunset70 = '#FF8D6F';
export const sunset50 = '#FEB09C';
export const sunset30 = '#FFD3C8';
export const sunset15 = '#FFE8E2';

export const sunset = {
  150: sunset150,
  130: sunset130,
  115: sunset115,
  100: sunset100,
  70: sunset70,
  50: sunset50,
  30: sunset30,
  15: sunset15,
};

export default {
  digitalGreen,
  digitalOrange,
  digitalRed,
  magenta,
  purple,
  periwinkle,
  brightBlue,
  blue,
  teal,
  green,
  mint,
  yellow,
  orange,
  sunset,
  digitalGreen130,
  digitalGreen100,
  digitalGreen70,
  digitalGreen50,
  digitalGreen30,
  digitalGreen15,
  digitalOrange100,
  digitalOrange70,
  digitalOrange50,
  digitalOrange30,
  digitalOrange15,
  digitalRed150,
  digitalRed130,
  digitalRed100,
  digitalRed70,
  digitalRed50,
  digitalRed30,
  digitalRed15,
  magenta150,
  magenta130,
  magenta115,
  magenta100,
  magenta70,
  magenta50,
  magenta30,
  magenta15,
  purple150,
  purple130,
  purple115,
  purple100,
  purple70,
  purple50,
  purple30,
  purple15,
  periwinkle150,
  periwinkle130,
  periwinkle115,
  periwinkle100,
  periwinkle70,
  periwinkle50,
  periwinkle30,
  periwinkle15,
  brightBlue150,
  brightBlue130,
  brightBlue115,
  brightBlue100,
  brightBlue70,
  brightBlue50,
  brightBlue30,
  brightBlue15,
  blue150,
  blue130,
  blue115,
  blue100,
  blue70,
  blue50,
  blue30,
  blue15,
  teal150,
  teal130,
  teal115,
  teal100,
  teal70,
  teal50,
  teal30,
  teal15,
  green150,
  green130,
  green115,
  green100,
  green70,
  green50,
  green30,
  green15,
  mint150,
  mint130,
  mint115,
  mint100,
  mint70,
  mint50,
  mint30,
  mint15,
  yellow115,
  yellow100,
  yellow70,
  yellow50,
  yellow30,
  yellow15,
  orange150,
  orange130,
  orange115,
  orange100,
  orange70,
  orange50,
  orange30,
  orange15,
  sunset150,
  sunset130,
  sunset115,
  sunset100,
  sunset70,
  sunset50,
  sunset30,
  sunset15,
};
