import { useEffect, useState } from 'react';

export const useIsTruncated = (ref, effectDependencies = []) => {
  const isEllipsisActive = (element) => {
    // scrollWidth and offsetWidth can be slightly off in IE, so I'm
    // giving this 1px of wiggle room. Otherwise it almost always returns true.
    return element.offsetWidth < element.scrollWidth - 2;
  };

  // this won't work in IE11, but fails harmlessly
  // if we can, we watch the element width and make isTruncated update when it changes
  const [width, setWidth] = useState(0);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window.ResizeObserver && ref?.current) {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => setWidth(entry.contentRect.width));
      });
      observer.observe(ref?.current);
      return () => observer.disconnect();
    }
  }, [ref]);

  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (ref?.current) {
      setIsTruncated(isEllipsisActive(ref.current));
    } else {
      setIsTruncated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, width, ...effectDependencies]);

  return isTruncated;
};

export default useIsTruncated;
