import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';
import stackingContext from '../../../commonResources/stackingContext';
import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';

export const BANNER_STYLES = styled.div`
  ${stackingContext.banner};
  position: fixed;
  top: -${MASTHEAD_HEIGHT}px;
  left: 0;
  right: 0;
  height: ${MASTHEAD_HEIGHT}px;
  margin: 0 auto;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TextStyles.smallBold};
  color: ${({ theme }) => theme.BaseColors.White};

  .notification-text {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }

  &.negative {
    background-color: ${({ theme }) => theme.UIPalette.Colors.Status.Negative};
  }

  &.positive {
    background-color: ${({ theme }) => theme.UIPalette.Colors.Status.Positive};
  }

  &.neutral {
    background-color: ${({ theme }) => theme.UIPalette.Colors.Status.Neutral};
  }

  &.caution {
    color: ${({ theme }) => theme.UIPalette.Colors.Content.PersistentDark};
    background-color: ${({ theme }) => theme.UIPalette.Colors.Status.Caution};
  }

  .notification-dismiss {
    position: absolute;
    right: 32px;
  }
`;

export default BANNER_STYLES;
