import { useState } from 'react';

export const useHover = (
  onMouseOver = () => false,
  onMouseOut = () => false,
) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverBindings = {
    onMouseOver: (e) => {
      e.persist();
      setIsHovered(true);
      onMouseOver(e, true);
    },
    onMouseOut: (e) => {
      e.persist();
      setIsHovered(false);
      onMouseOut(e, false);
    },
  };

  return [isHovered, hoverBindings];
};

export default useHover;
