import { useState } from 'react';

export const useFocus = (onFocus = () => false, onBlur = () => false) => {
  const [isFocused, setIsFocused] = useState(false);
  const focusBindings = {
    onFocus: (e) => {
      e.persist();
      setIsFocused(true);
      onFocus(e, true);
    },
    onBlur: (e) => {
      e.persist();
      setIsFocused(false);
      onBlur(e, false);
    },
  };

  return [isFocused, focusBindings];
};

export default useFocus;
