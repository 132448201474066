import { MASTHEAD_HEIGHT } from '../../../BrandCore/constants/heights';

export const POSITIVE_NOTIFICATION_TYPE = 'positive';
export const NEGATIVE_NOTIFICATION_TYPE = 'negative';
export const CAUTION_NOTIFICATION_TYPE = 'caution';
export const NEUTRAL_NOTIFICATION_TYPE = 'neutral';

export const VALID_NOTIFICATION_TYPES = [
  POSITIVE_NOTIFICATION_TYPE,
  NEGATIVE_NOTIFICATION_TYPE,
  CAUTION_NOTIFICATION_TYPE,
  NEUTRAL_NOTIFICATION_TYPE,
];

export const NOTIFICATION_AUTODISMISS_TIMEOUT_MILLISECONDS = 2500;
export const BANNER_ANIMATION_MILLISECONDS = 250;

export const DEFAULT_TRANSITION_STYLES = {
  transition: `top ${BANNER_ANIMATION_MILLISECONDS}ms ease-in-out`,
  top: `-${MASTHEAD_HEIGHT}px`,
};

export const ACTIVE_TRANSITION_STYLES = {
  entering: { top: `-${MASTHEAD_HEIGHT}px` },
  entered: { top: 0 },
  exiting: { top: 0 },
  exited: { top: `-${MASTHEAD_HEIGHT}px` },
};

export default {
  POSITIVE_NOTIFICATION_TYPE,
  NEGATIVE_NOTIFICATION_TYPE,
  CAUTION_NOTIFICATION_TYPE,
  NEUTRAL_NOTIFICATION_TYPE,
  VALID_NOTIFICATION_TYPES,
  NOTIFICATION_AUTODISMISS_TIMEOUT_MILLISECONDS,
  BANNER_ANIMATION_MILLISECONDS,
  DEFAULT_TRANSITION_STYLES,
  ACTIVE_TRANSITION_STYLES,
};
