import { useState, useEffect } from 'react';

export const useKeyboard = ({
  initialValue = '',
  onChange = () => false,
  onKeyDown = () => false,
  onKeyPress = () => false,
  onEnterPress = () => false,
  onKeyUp = () => false,
}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  const keyboardBindings = {
    value,
    onChange: (e) => {
      e.persist();
      const { value: newValue } = e.target;
      setValue(newValue);
      onChange(e, { value: newValue });
    },
    onKeyDown: (e) => onKeyDown(e, { value }),
    onKeyUp: (e) => onKeyUp(e, { value }),
    onKeyPress: (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13 && onEnterPress) {
        onEnterPress(e, { value });
      } else {
        onKeyPress(e, { value });
      }
    },
  };
  return keyboardBindings;
};

export default useKeyboard;
