import styled from 'styled-components';
import TextStyles from '../../../BrandCore/Text/styles';

const CheckboxGroupError = styled.div`
  margin-top: 10px;
  ${TextStyles.xSmall}
  color: ${(props) => props.theme.UIPalette.Colors.Content.Error};
`;

export default CheckboxGroupError;
