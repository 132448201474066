import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default styled.div`
  animation: ${rotation} 0.75s linear infinite;
  width: ${(props) => props.length};
  height: ${(props) => props.length};
  svg {
    width: ${(props) => props.length};
    height: ${(props) => props.length};
  }
`;
