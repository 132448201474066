import styled from 'styled-components';

export default styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.fillColor};
`;
